import '@dfds-pax/ui/stylescvi.css'
import '@dfds-pax/ui/styles.css'
import './globals.css'

import { SWRConfig } from 'swr'
import ErrorBoundary from './ErrorBoundary'
import AppWithContent from './AppWithContent'
import { getSessionId } from './Utils/common'
import { LoggingInterfaces, logger } from '@dfds-pax/logging-provider'
import { getClientEnvironment } from '@dfds-pax/common'
import { v4 as uuidv4 } from 'uuid'
import { TravelSearchProvider } from './context/TravelSearchProvider'
import { ActiveSelectFieldProvider } from './context/ActiveSelectFieldProvider'

const App = ({ hasOffer }: { hasOffer: boolean | undefined }) => {
  return (
    <ErrorBoundary>
      <TravelSearchProvider>
        <ActiveSelectFieldProvider>
          <SWRConfig
            value={{
              onError: (error) => {
                // Ignore network errors, i.e. frontend having problems contacting edge functions (swr throws 'failed to fetch' error often when this happens , hence lots of entries in log)
                // 'Failed to fetch' is window and linux specific
                // 'Load failed' is apple specific
                if (['Failed to fetch', 'Load failed'].includes(error.message)) return
                const logBody: LoggingInterfaces.LogProps = {
                  message: error?.message,
                  messageTemplate: 'Error from SWR config',
                  level: 'Error',
                  fields: {
                    correlationId: error?.correlationId ?? uuidv4(),
                    sessionId: getSessionId(),
                    status: error?.status,
                    application: 'travel-search',
                    environment: getClientEnvironment(),
                    frontend: true,
                    page: window.location?.pathname,
                    referer: document?.referrer,
                    userAgent: navigator?.userAgent,
                    stackTrace: error?.stack ?? error?.stackTrace,
                  },
                }
                logger(getClientEnvironment(), logBody)
              },
            }}
          >
            <AppWithContent hasOffer={hasOffer} />
            <div data-e2e="deployed"></div>
          </SWRConfig>
        </ActiveSelectFieldProvider>
      </TravelSearchProvider>
    </ErrorBoundary>
  )
}

export default App
