import React, { createContext, useContext, useState } from 'react'

export enum ActiveSelectField {
  ROUTE = 'ROUTE',
  VEHICLE = 'VEHICLE',
  PASSENGER = 'PASSENGER',
  DATE = 'DATE',
  CURRENCY = 'CURRENCY',
  NONE = 'NONE',
}

type ActiveSelectFieldContextType = {
  activeField: ActiveSelectField
  travelSearchInViewport: boolean
  setActiveField: (field: ActiveSelectField) => void
  setTravelSearchInViewport: (value: boolean) => void
  toggleField: (field: ActiveSelectField, clickedOutside: boolean) => void
  closeIfActive: (field: ActiveSelectField) => void
}

const ActiveSelectFieldContext = createContext<ActiveSelectFieldContextType | undefined>(undefined)

export const ActiveSelectFieldProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeField, setActiveField] = useState<ActiveSelectField>(ActiveSelectField.NONE)
  const [travelSearchInViewport, setTravelSearchInViewport] = useState<boolean>(false)

  const toggleField = (field: ActiveSelectField, clickedOutside: boolean) => {
    setActiveField(clickedOutside ? ActiveSelectField.NONE : field)
  }

  const closeIfActive = (field: ActiveSelectField) => {
    if (activeField === field) {
      setActiveField(ActiveSelectField.NONE)
    }
  }

  return (
    <ActiveSelectFieldContext.Provider
      value={{ activeField, setActiveField, travelSearchInViewport, setTravelSearchInViewport, toggleField, closeIfActive }}
    >
      {children}
    </ActiveSelectFieldContext.Provider>
  )
}

export const useActiveSelectField = () => {
  const context = useContext(ActiveSelectFieldContext)
  if (!context) throw new Error('useActiveSelectField must be used within an ActiveSelectFieldProvider')
  return context
}
