import { useTranslation } from 'react-i18next'
import { useTravelSearchContext } from '../../context/TravelSearchProvider'
import { UseTravelSearchConfigResult } from '../../Hooks/useTravelSearchConfig'
import { useWindowWidth2 } from '../../Hooks/useWindowWidth2'
import SelectField from '../Shared/SelectField'
import RoutesWithSearch from './Routes'
import RoroFerryIcon from '../../Icons/RoroFerryIcon'
import FerryReturnIcon from '../../Icons/FerryReturnIcon'
import { ActiveSelectField, useActiveSelectField } from '../../context/ActiveSelectFieldProvider'

type Props = {
  travelSearchConfigResult: UseTravelSearchConfigResult
  travelSearchRef: React.RefObject<HTMLDivElement>
}

export default function RoutesSelector({ travelSearchConfigResult, travelSearchRef }: Props) {
  const {
    state: { selectedRoute, isOneWay, routesApiData },
    dispatch,
  } = useTravelSearchContext()
  const { activeField, setActiveField, toggleField, closeIfActive } = useActiveSelectField()

  const { isMobile } = useWindowWidth2()
  const { t } = useTranslation()

  return (
    <div className="ts-h-[58px] ts-w-full lg:ts-col-auto md:ts-col-span-6 ts-col-span-12 ts-box-border ts-border lg:ts-border-r-0 ts-border-solid ts-border-cvi-grey-grey200">
      <SelectField
        dataAttrList={[
          { name: 'selected-route', value: `${selectedRoute?.routeCode}` },
          { name: 'selected-salesowner', value: `${selectedRoute?.salesOwner}` },
        ]}
        disabled={routesApiData?.categories?.reduce((count, current) => count + current.routes.length, 0) === 1}
        title={t('ROUTE')}
        isLoading={travelSearchConfigResult?.isLoading || !routesApiData}
        isMobile={isMobile}
        isOpen={activeField === ActiveSelectField.ROUTE}
        fieldName="route"
        value={selectedRoute?.name}
        icon={isOneWay ? <RoroFerryIcon size="md" /> : <FerryReturnIcon size="md" />}
        children={
          <RoutesWithSearch
            routeCategories={routesApiData?.categories ?? []}
            travelSearchRef={travelSearchRef}
            onSelectRoute={(route) => {
              dispatch({
                type: 'SET_ROUTE',
                route: {
                  routeCode: route.routeCode,
                  salesOwner: route.salesOwner,
                  name: route?.name,
                  returnRequired: route.returnRequired,
                },
              })
              closeIfActive(ActiveSelectField.ROUTE)
            }}
          />
        }
        onClick={(clickedOutside: boolean) => {
          if (clickedOutside) {
            toggleField(ActiveSelectField.ROUTE, clickedOutside)
          } else {
            !!routesApiData && setActiveField(ActiveSelectField.ROUTE)
          }
        }}
        onClose={() => {
          closeIfActive(ActiveSelectField.ROUTE)
        }}
      />
    </div>
  )
}
